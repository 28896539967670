<script>
import {
  ArrowUpIcon,
  ArrowRightIcon,
  ArrowRightCircleIcon,
  HelpCircleIcon,
} from "vue-feather-icons";

import Navbar from "@/components/navbar";
import Footer from "@/components/footer";
import Features from "@/components/features";

/**
 * Index task-management component
 */
export default {
  data() {
    return {
      featuresData: [
        {
          icon: "uil uil-airplay",
          title: "Connect everyone & everything in real time",
          description:
            " Automate and streamline every aspect of your growing business.",
        },
        {
          icon: "uil uil-envelope-shield",
          title: "Automate to enhance efficiency",
          description:
            "Streamline processes, minimize manual intervention, and enhance the overall efficiency of your operations.",
        },
        {
          icon: "uil uil-edit-alt",
          title: "Making data meaningful for your business",
          description:
            "Observing your business performance through data insights is key to enhancing vitality and competitiveness.",
        },
      ],
    };
  },
  components: {
    Navbar,
    Footer,
    Features,
    ArrowUpIcon,
    ArrowRightIcon,
    ArrowRightCircleIcon,
    HelpCircleIcon,
  },
};
</script>

<template>
  <div>
    <Navbar :nav-light="true" />
    <!-- Start Home -->
    <section
      class="bg-home d-flex align-items-center bg-animation-left task-management-home"
      style="height: auto"
      id="home"
    >
      <div class="container position-relative" style="z-index: 1">
        <div class="row justify-content-center">
          <div class="col-lg-12 text-center mt-0 mt-md-5 pt-0 pt-md-5">
            <div class="title-heading margin-top-100">
              <h1 class="heading text-white title-dark mb-3">
                Manage your business in one place
              </h1>
              <p class="para-desc mx-auto text-white-50">
                Whether you're selling online, manually entering bookings or getting sales from OTAs, TREEP is the single place where you can track all of your bookings across every channel.
              </p>
            </div>

            <div class="row justify-content-center">
              <div class="col-lg-10 text-center">
                <div class="home-dashboard">
                  <img src="images/template/laptop.png" alt="" class="img-fluid" />
                </div>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->
    <!-- End Home -->

    

    <!-- Features Start -->
    <section class="section mt-0 mt-md-5">
      <div class="container">
        <div class="row">
          <div class="col-lg-2 col-md-2 col-6 text-center">
              <div class="media features feature-clean align-items-center">
                <img src="images/client/hot-air-balloon.svg" height="36" />
                  <div class="content ml-2">
                    <p class="text-muted mb-0 font-weight-bold">Activity</p>
                 </div>
              
              </div>
          </div>
          <div class="col-lg-2 col-md-2 col-6 text-center">
              <div class="media features feature-clean align-items-center">
                  <img src="images/client/taj-mahal.svg" height="36" />
                  <div class="content ml-2">
                      <p class="text-muted mb-0 font-weight-bold">Sightseeing</p>
                 </div>
              </div>
          </div>
          <div class="col-lg-2 col-md-2 col-6 text-center">
              <div class="media features feature-clean align-items-center">
                <img src="images/client/dolphin.svg" height="36" />
                  <div class="content ml-2">  
                      <p class="text-muted mb-0 font-weight-bold">Park</p>
                 </div>
              </div>
          </div>
          <div class="col-lg-2 col-md-2 col-6 text-center">
              <div class="media features feature-clean align-items-center">
                  <img src="images/client/tent-banner.svg" height="36" />
                  <div class="content ml-2">
                      <p class="text-muted mb-0 font-weight-bold">Show</p>
                 </div>
              </div>
          </div>
          <div class="col-lg-2 col-md-2 col-6 text-center">
              <div class="media features feature-clean align-items-center">
                <img src="images/client/sailboat-one.svg" height="36" />
                  <div class="content ml-2">
                      
                      <p class="text-muted mb-0 font-weight-bold">Tour</p>
                 </div>
              </div>
          </div>
          <div class="col-lg-2 col-md-2 col-6 text-center">
              <div class="media features feature-clean align-items-center">
                <img src="images/client/tour-bus.svg" height="36" />
                  <div class="content ml-2">
                      <p class="text-muted mb-0 font-weight-bold">Transfer</p>
                 </div>
              </div>
          </div>
        </div>

      </div>
      <!--end container-->


      <div class="container mt-100 mt-60">
        <Features :featuresData="featuresData" />
        <!--end row-->
      </div>
      <!--end container-->

    </section>
    <!--end section-->
    <!-- Features End -->

    <section class="section bg-light">
      <div class="container">
        <div class="row justify-content-center">
          <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4 class="title mb-4">Connect everyone & everything in real time</h4>
              <p class="text-muted para-desc mb-0 mx-auto">
                Automate and streamline every aspect of your growing business.
              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row">
          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div
              class="card features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center" style="height: 230px"
            >
              <div class="icons rounded h2 text-center text-primary px-3">
                <i class="uil uil-airplay"></i>
              </div>

              <div class="card-body p-0 content">
                <h5>
                  <a href="javascript:void(0)" class="title text-dark"
                    >Product</a
                  >
                </h5>
                <p class="text-muted">
                  One stop management for your travel products.
                </p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div
              class="card features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center" style="height: 230px"
            >
              <div class="icons rounded h2 text-center text-primary px-3">
                <i class="uil uil-clipboard-alt"></i>
              </div>

              <div class="card-body p-0 content" >
                <h5>
                  <a href="javascript:void(0)" class="title text-dark"
                    >Price</a
                  >
                </h5>
                <p class="text-muted">
                  Customizing and timely adjusting prices.
                </p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div
              class="card features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center" style="height: 230px"
            >
              <div class="icons rounded h2 text-center text-primary px-3">
                <i class="uil uil-credit-card-search"></i>
              </div>

              <div class="card-body p-0 content">
                <h5>
                  <a href="javascript:void(0)" class="title text-dark"
                    >Inventory</a
                  >
                </h5>
                <p class="text-muted">
                  Effectively adjust all available resources, maximizing profits.
                </p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div
              class="card features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center" style="height: 230px"
            >
              <div class="icons rounded h2 text-center text-primary px-3">
                <i class="uil uil-ruler-combined"></i>
              </div>

              <div class="card-body p-0 content">
                <h5>
                  <a href="javascript:void(0)" class="title text-dark"
                    >Booking</a
                  >
                </h5>
                <p class="text-muted">
                  Digital booking management enhances efficiency, accuracy, and reduces errors.
                </p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div
              class="card features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center" style="height: 230px"
            >
              <div class="icons rounded h2 text-center text-primary px-3">
                <i class="uil uil-exposure-alt"></i>
              </div>

              <div class="card-body p-0 content">
                <h5>
                  <a href="javascript:void(0)" class="title text-dark"
                    >Sales channel</a
                  >
                </h5>
                <p class="text-muted">
                  Effectively manage various sales channels to expand and manage all your traffic.
                </p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-4 col-md-6 col-12 mt-4 pt-2">
            <div
              class="card features feature-clean explore-feature p-4 px-md-3 border-0 rounded-md shadow text-center" style="height: 230px"
            >
              <div class="icons rounded h2 text-center text-primary px-3">
                <i class="uil uil-clock"></i>
              </div>
              <div class="card-body p-0 content">
                <h5>
                  <a href="javascript:void(0)" class="title text-dark"
                    >Vehicle & boat</a
                  >
                </h5>
                <p class="text-muted">
                  Rationalizing the dispatch of internal resources ensures cost savings and maximizes efficiency.
                </p>
              </div>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
    </section>
    <!--end section-->

    <section class="section">
      <div class="container">
        <div class="row justify-content-center">
          <!-- <div class="col-12 text-center">
            <div class="section-title mb-4 pb-2">
              <h4
                class="title mb-4"
                data-aos="fade-up"
                data-aos-duration="1000"
              >
                Need to CRM Support
              </h4>
              <p
                class="text-muted para-desc mb-0 mx-auto"
                data-aos="fade-up"
                data-aos-duration="1400"
              >
                Start working with
                <span class="text-primary font-weight-bold">Landrick</span> that
                can provide everything you need to generate awareness, drive
                traffic, connect.
              </p>
            </div>
          </div> -->
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row justify-content-center">
          <div class="col-12 mt-4 pt-2">
            <b-tabs
              pills
              justified
              content-class="mt-5 pt-2"
              nav-class="col-lg-8 col-md-12 offset-md-2 text-center"
            >
              <b-tab>
                <template v-slot:title>
                  <div class="text-center py-2">
                    <h6 class="mb-0">Product</h6>
                  </div>
                </template>
                <div class="row align-items-center">
                  <div class="col-md-6">
                    <img
                      src="images/pms/1product.png"
                      data-aos="fade-up"
                      data-aos-duration="1400"
                      class="img-fluid mx-auto d-block shadow rounded"
                      alt=""
                    />
                  </div>
                  <!--end col-->

                  <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <div class="section-title ml-md-4">
                      <h4
                        class="title mb-4"
                        data-aos="fade-up"
                        data-aos-duration="1000"
                      >
                        Product
                      </h4>
                      <p
                        class="text-muted"
                        data-aos="fade-up"
                        data-aos-duration="1400"
                      >
                      One-stop management for your travel products. The system enables quick realization of real-time information management, facilitating the digitization of products.
                      </p>
                      <ul class="list-unstyled text-muted">
                        <li class="mb-0">
                          <span class="text-primary h5 mr-2"
                            ><i class="uil uil-check-circle align-middle"></i></span
                          >Synchronize sales across internal and external channels.
                        </li>
                        <li class="mb-0">
                          <span class="text-primary h5 mr-2"
                            ><i class="uil uil-check-circle align-middle"></i></span
                          >Liberates from complex paper-based presentations.
                        </li>
                        <li class="mb-0">
                          <span class="text-primary h5 mr-2"
                            ><i class="uil uil-check-circle align-middle"></i></span
                          >Maximizing the globalization of sales materials. 
                        </li>
                        <li class="mb-0">
                          <span class="text-primary h5 mr-2"
                            ><i class="uil uil-check-circle align-middle"></i></span
                          >Colleagues and agencies worldwide can access the latest information promptly.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <!--end col-->
                </div>
                <!--end row-->
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <div class="text-center py-2">
                    <h6 class="mb-0">Price</h6>
                  </div>
                </template>
                <div class="row align-items-center">
                  <div
                    class="col-md-6 order-2 order-md-1 mt-4 mt-sm-0 pt-2 pt-sm-0"
                  >
                    <div class="section-title mr-md-4">
                      <h4
                        class="title mb-4"
                        data-aos="fade-up"
                        data-aos-duration="1000"
                      >
                        Price
                      </h4>
                      <p
                        class="text-muted"
                        data-aos="fade-up"
                        data-aos-duration="1400"
                      >
                      Customizing and timely adjusting prices are crucial for a business's survival. Empower your business to adjust based on sales situations, maximizing revenue.
                      </p>
                      <ul class="list-unstyled text-muted">
                        <li class="mb-0">
                          <span class="text-primary h5 mr-2"
                            ><i class="uil uil-check-circle align-middle"></i></span
                          ><span class="text-primary font-weight-bold">Calendar pricing:</span> Adjust product prices for low and peak seasons to maximize sales profits.
                        </li>
                        <li class="mb-0">
                          <span class="text-primary h5 mr-2"
                            ><i class="uil uil-check-circle align-middle"></i></span
                          ><span class="text-primary font-weight-bold">Agency pricing:</span> Set different pricing gradients for various sales channels and agents. 
                        </li>
                        <li class="mb-0">
                          <span class="text-primary h5 mr-2"
                            ><i class="uil uil-check-circle align-middle"></i></span
                          ><span class="text-primary font-weight-bold">Differential pricing</span>: Set different prices based on differnt markets and booking time(Early Bird).
                        </li>
                      </ul>
                    </div>
                  </div>
                  <!--end col-->

                  <div class="col-md-6 order-1 order-md-2">
                    <img
                      src="images/pms/2price.png"
                      data-aos="fade-up"
                      data-aos-duration="1400"
                      class="img-fluid mx-auto d-block shadow rounded"
                      alt=""
                    />
                  </div>
                  <!--end col-->
                </div>
                <!--end row-->
              </b-tab>
              <b-tab>
                <template v-slot:title>
                  <div class="text-center py-2">
                    <h6 class="mb-0">Inventory</h6>
                  </div>
                </template>
                <div class="row align-items-center">
                  <div class="col-md-6">
                    <img
                      src="images/pms/3inventory.png"
                      data-aos="fade-up"
                      data-aos-duration="1400"
                      class="img-fluid mx-auto d-block shadow rounded"
                      alt=""
                    />
                  </div>
                  <!--end col-->

                  <div class="col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <div class="section-title ml-md-4">
                      <h4
                        class="title mb-4"
                        data-aos="fade-up"
                        data-aos-duration="1000"
                      >
                      Inventory
                      </h4>
                      <p
                        class="text-muted"
                        data-aos="fade-up"
                        data-aos-duration="1400"
                      >
                      Flexible inventory settings effectively adjust all available resources, preventing unnecessary overselling and reducing losse, maxmizing your profit.
                      </p>
                      <ul class="list-unstyled text-muted">
                        <li class="mb-0">
                          <span class="text-primary h5 mr-2"
                            ><i class="uil uil-check-circle align-middle"></i></span
                          ><span class="text-primary font-weight-bold">Calendar inventory:</span> Set freely, customize your available and unavailable dates.
                        </li>
                        <li class="mb-0">
                          <span class="text-primary h5 mr-2"
                            ><i class="uil uil-check-circle align-middle"></i></span
                          ><span class="text-primary font-weight-bold">Inventory dashboard:</span> Easily identify reservation status through color-coded classifications, adjust and reserve quickly.
                        </li>
                        <li class="mb-0">
                          <span class="text-primary h5 mr-2"
                            ><i class="uil uil-check-circle align-middle"></i></span
                          ><span class="text-primary font-weight-bold">Exclusive inventory:</span> Set independent inventory for different channels.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <!--end col-->
                </div>
                <!--end row-->
              </b-tab>
            </b-tabs>
          </div>
        </div>

        <div class="row">
          <div class="col-12 mt-4 pt-2">
            <div class="tab-content" id="pills-tabContent">
              <div
                class="tab-pane fade show active"
                id="pills-community"
                role="tabpanel"
                aria-labelledby="community-tab"
              ></div>
              <!--end teb pane-->

              <div
                class="tab-pane fade"
                id="pills-self"
                role="tabpanel"
                aria-labelledby="selfservice-tab"
              ></div>
              <!--end teb pane-->

              <div
                class="tab-pane fade"
                id="pills-teamwork"
                role="tabpanel"
                aria-labelledby="teamwork-tab"
              ></div>
              <!--end teb pane-->
            </div>
            <!--end tab content-->
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->

      <div class="container mt-100 mt-60">
        <div class="row align-items-center">
          <div class="col-lg-6 col-md-6">
            <img
              src="images/pms/4booking.png"
              class="img-fluid shadow rounded-md"
              alt=""
            />
          </div>
          <!--end col-->

          <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <div class="section-title ml-lg-5">
              <h1 class="text-primary"><i class="uil uil-schedule"></i></h1>
              <h4 class="title mb-4">Booking</h4>
              <p class="text-muted">
                Treep provides various tools to reduce redundant tasks, allowing staff to focus on more critical matters and improve labor efficiency, lowering labor costs.
              </p>
              <ul class="list-unstyled text-muted">
                <li>
                  <arrow-right-circle-icon
                    class="fea icon-sm text-primary mr-2"
                  ></arrow-right-circle-icon
                  ><span class="text-primary font-weight-bold">Reservation</span>: Eliminating the tedious and error-prone process of copy-pasting.
                </li>
                <li>
                  <arrow-right-circle-icon
                    class="fea icon-sm text-primary mr-2"
                  ></arrow-right-circle-icon
                  ><span class="text-primary font-weight-bold">Booking management</span>: Simple, efficient, standardized booking entry with one-click confirmation, cancellation, and modification.
                </li>
                <li>
                  <arrow-right-circle-icon
                    class="fea icon-sm text-primary mr-2"
                  ></arrow-right-circle-icon
                  ><span class="text-primary font-weight-bold">Quick orders</span>: Swift reservstion by copying customer information, streamlining repetitive work.
                </li>
                <li>
                  <arrow-right-circle-icon
                    class="fea icon-sm text-primary mr-2"
                  ></arrow-right-circle-icon
                  ><span class="text-primary font-weight-bold">Customer profile orders</span>: Maintain customer information for quick insights into preferences and prohibitions.
                </li>
                <li>
                  <arrow-right-circle-icon
                    class="fea icon-sm text-primary mr-2"
                  ></arrow-right-circle-icon
                  ><span class="text-primary font-weight-bold">Inventory calendar</span>: Intuitively view available inventory and reerve quickly.
                </li>
              </ul>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->

      <div class="container mt-100 mt-60">
        <div class="row align-items-center">
          <div class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
            <div class="section-title ml-lg-5">
              <h1 class="text-primary"><i class="uil uil-schedule"></i></h1>
              <h4 class="title mb-4">Sales Channel</h4>
              <p class="text-muted">
                TREEP effectively manage various sales channels to expand and manage all your traffic. Retaining sales resources, ensuring timely payments, adjusting payment policies. <br />
                Optimizing cash flow and quickly adjust  prices for different agents and seasons.
              </p>
              <ul class="list-unstyled text-muted">
                <li>
                  <arrow-right-circle-icon
                    class="fea icon-sm text-primary mr-2"
                  ></arrow-right-circle-icon
                  ><span class="text-primary font-weight-bold">Agent payment methods</span>: Set agent as prepayment, post-payment, deposit deductions, cash payments, etc.
                </li>
                <li>
                  <arrow-right-circle-icon
                    class="fea icon-sm text-primary mr-2"
                  ></arrow-right-circle-icon
                  ><span class="text-primary font-weight-bold">Payment records</span>: Upload payment records for traceability.
                </li>
                <li>
                  <arrow-right-circle-icon
                    class="fea icon-sm text-primary mr-2"
                  ></arrow-right-circle-icon
                  ><span class="text-primary font-weight-bold">Account reconciliation</span>: Export paid and unpaid orders for easy reconciliation and timely payment, ensuring clear financial oversight.
                </li>
                <li>
                  <arrow-right-circle-icon
                    class="fea icon-sm text-primary mr-2"
                  ></arrow-right-circle-icon
                  ><span class="text-primary font-weight-bold">Credit management</span>: Manage agencies prepayments with automatic deductions.
                </li>
                <li>
                  <arrow-right-circle-icon
                    class="fea icon-sm text-primary mr-2"
                  ></arrow-right-circle-icon
                  ><span class="text-primary font-weight-bold">Commission calculation</span>: Automatically categorize orders for easy commission calculations.
                </li>
              </ul>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-6 col-md-6">
            <img
              src="images/pms/5saleschannel.png"
              class="img-fluid shadow rounded-md"
              alt=""
            />
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->

      <div class="container mt-100 mt-60">
        <div class="row align-items-center">
          <div class="col-lg-6 col-md-6 order-1 order-md-2">
            <img src="images/pms/6vehicleboat.png" class="img-fluid" alt="" />
          </div>
          <!--end col-->

          <div
            class="col-lg-6 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0 order-2 order-md-1"
          >
            <div class="section-title mr-lg-5">
              <h1 class="text-primary"><i class="uil uil-list-ui-alt"></i></h1>
              <h4 class="title mb-4">
                Vehicle & Boat
              </h4>
              <p class="text-muted">
                Treep helps optimize the scheduling of vehicles, boats, drivers, and guides to ensure every resource is used to its fullest extent.
              </p>
              <ul class="list-unstyled text-muted">
                <li>
                  <arrow-right-circle-icon
                    class="fea icon-sm text-primary mr-2"
                  ></arrow-right-circle-icon
                  ><span class="text-primary font-weight-bold">Vehicle & boat scheduling</span>: Set maximum capacity for vehicles and boats, allocate resources efficiently based on transportation areas, and save costs.
                </li>
                <li>
                  <arrow-right-circle-icon
                    class="fea icon-sm text-primary mr-2"
                  ></arrow-right-circle-icon
                  ><span class="text-primary font-weight-bold">Driver & guide scheduling</span>: Allocate guides, drivers, and crew based on different scenarios, generate job orders.
                </li>
              </ul>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>

      <!--end container-->
      <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
          <div class="col-lg-4 col-12">
            <div class="sticky-bar">
              <div
                class="section-title text-lg-left text-center mb-4 mb-lg-0 pb-2 pb-lg-0"
              >
                <h4 class="title mb-4">Automate to enhance efficiency</h4>
                <p class="text-muted para-desc mb-0 mx-auto">
                  <span class="text-primary font-weight-bold">TREEP</span> designed these automated features to streamline processes, minimize manual intervention, and enhance the overall efficiency of your operations.
                </p>
              </div>
            </div>
          </div>
          <!--end col-->

          <div class="col-lg-8 col-12">
            <div class="row align-items-center">
              <div class="col-md-6">
                <div class="row">
                  <div class="col-12 mt-4 mt-lg-0 pt-2 pt-lg-0">
                    <div
                      class="card features fea-primary rounded p-4 bg-light position-relative overflow-hidden border-0"
                    >
                      <span class="h1 icon2 text-primary">
                        <i class="uil uil-meeting-board"></i>
                      </span>
                      <div class="card-body p-0 content">
                        <h5>Automatic confirmation Email</h5>
                        <p class="para text-muted mb-0">
                          Set up automatic delivery of confirmation emails to agencies channels or directly to customers.
                        </p>
                      </div>
                    </div>
                  </div>
                  <!--end col-->

                  <div class="col-12 mt-4 pt-2">
                    <div
                      class="card features fea-primary rounded p-4 bg-light position-relative overflow-hidden border-0"
                    >
                      <span class="h1 icon2 text-primary">
                        <i class="uil uil-schedule"></i>
                      </span>
                      <div class="card-body p-0 content">
                        <h5>Auto job order</h5>
                        <p class="para text-muted mb-0">
                          Automatically generate job orders for drivers and guides.
                        </p>
                      </div>
                    </div>
                  </div>
                  <!--end col-->

                  <div class="col-12 mt-4 pt-2">
                    <div
                      class="card features fea-primary rounded p-4 bg-light position-relative overflow-hidden border-0"
                    >
                      <span class="h1 icon2 text-primary">
                        <i class="uil uil-clock"></i>
                      </span>
                      <div class="card-body p-0 content">
                        <h5>Auto airport pickup signs</h5>
                        <p class="para text-muted mb-0">
                          Automatically create welcome boards and airport pickup signs.
                        </p>
                      </div>
                    </div>
                  </div>
                  <!--end col-->
                </div>
                <!--end row-->
              </div>
              <!--end col-->

              <div class="col-md-6">
                <div class="row">
                  <div class="col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <div
                      class="card features fea-primary rounded p-4 bg-light position-relative overflow-hidden border-0"
                    >
                      <span class="h1 icon2 text-primary">
                        <i class="uil uil-calender"></i>
                      </span>
                      <div class="card-body p-0 content">
                        <h5>Auto invoice</h5>
                        <p class="para text-muted mb-0">
                          Automatically generate a single-order invoice. Periodically create statements, allowing you to set order cycles for timely and effective payments.
                        </p>
                      </div>
                    </div>
                  </div>
                  <!--end col-->

                  <div class="col-12 mt-4 pt-2">
                    <div
                      class="card features fea-primary rounded p-4 bg-light position-relative overflow-hidden border-0"
                    >
                      <span class="h1 icon2 text-primary">
                        <i class="uil uil-presentation-plus"></i>
                      </span>
                      <div class="card-body p-0 content">
                        <h5>Auto voucher</h5>
                        <p class="para text-muted mb-0">
                          Customize voucher content based on requirements; the system will automatically generate vouchers, reducing manual workload and minimizing errors.
                        </p>
                      </div>
                    </div>
                  </div>
                  <!--end col-->

                </div>
                <!--end row-->
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </div>
          <!--end col-->
        </div>
        <!--end row-->
      </div>
      <!--end container-->
      <div class="container mt-100 mt-60">
        <div class="row justify-content-center">
          <div class="col-12">
            <div class="section-title text-center mb-4 pb-2">
              <h4 class="title mb-4">
                Making data meaningful for your business
              </h4>
              <p class="text-muted para-desc mb-0 mx-auto">
                Living in the era of big data, leveraging data analysis is crucial for businesses to timely adjust strategies and stand out in competition. <br />
                Store your business data in TREEP and observe your business performance through insights is key to enhancing vitality and competitiveness.
              </p>
            </div>
          </div>
          <!--end col-->
        </div>
        <!--end row-->

        <div class="row align-items-center">
          <b-tabs
            pills
            justified
            vertical
            nav-wrapper-class="col-md-5"
            nav-class="rounded-md"
            content-class="col-md-7 col-12 mt-4 pt-2"
          >
            <b-tab active>
              <template v-slot:title>
                <div class="p-3 text-left">
                  <h5 class="title">Customized and default reports</h5>
                  <p class="text-muted tab-para mb-0">
                    TREEP comes with a variety of default reports. We provide convenient customize report to fit your needs, export them with a single click.
                  </p>
                </div>
              </template>
              <img
                src="images/pms/8dailyreport.png"
                class="img-fluid mx-auto rounded-md shadow-lg d-block"
                alt=""
              />
            </b-tab>
            <b-tab>
              <template v-slot:title>
                <div class="p-3 text-left">
                  <h5 class="title">Daily dashboard</h5>
                  <p class="text-muted tab-para mb-0">
                    TREEP includes a built-in data analysis section. Check your business status anytime, stay informed about your business's real-time performance.
                  </p>
                </div>
              </template>
              <img
                src="images/pms/9statistic.png"
                class="img-fluid mx-auto rounded-md shadow-lg d-block"
                alt=""
              />
            </b-tab>
            <b-tab>
              <template v-slot:title>
                <div class="p-3 text-left">
                  <h5 class="title">Financial configuration</h5>
                  <p class="text-muted tab-para mb-0">
                    Easily review the status of all bookings. Facilitate quick and timely finance status, ensuring the smooth flow of company finances.
                  </p>
                </div>
              </template>
              <img
                src="images/pms/10invoice.png"
                class="img-fluid mx-auto rounded-md shadow-lg d-block"
                alt=""
              />
            </b-tab>
            <b-tab>
              <template v-slot:title>
                <div class="p-3 text-left">
                  <h5 class="title">Inventory dashboard</h5>
                  <p class="text-muted tab-para mb-0">
                    Rapidly assess remaining stock and promptly adjust available product inventory based on sales conditions.
                  </p>
                </div>
              </template>
              <img
                src="images/pms/11inventorycalendar.png"
                class="img-fluid mx-auto rounded-md shadow-lg d-block"
                alt=""
              />
            </b-tab>
          </b-tabs>
        </div>
        <!--end row-->
      </div>
      <!--end container-->
      <div class="container mt-100 mt-60">
            <div class="rounded bg-primary p-lg-5 p-4">
                <div class="row align-items-end">
                    <div class="col-md-8">
                        <div class="section-title text-md-left text-center">
                            <h4 class="title mb-3 text-white title-dark">Use TREEP PMS to manage your business in one place from today</h4>
                            <p class="text-white-50 mb-0">Whether you're selling online, manually entering bookings or getting sales from OTAs. </p>
                        </div>
                    </div>
                    <!--end col-->

                    <div class="col-md-4 mt-4 mt-sm-0">
                      <div class="text-md-right text-center">
                            <a href="https://treep.cc/demo/?channelType=1" class="btn btn-light" target="_blank">Book a meeting</a>
                        </div>
                    </div>
                    <!--end col-->
                </div>
                <!--end row-->
            </div>
        </div>
        <!--end container-->
    </section>
    <!--end section-->

    <Footer />
    <!-- Footer End -->
    <!-- Back to top -->
    <a
      href="javascript: void(0);"
      class="btn btn-icon btn-primary back-to-top"
      id="back-to-top"
      v-scroll-to="'#topnav'"
    >
      <arrow-up-icon class="icons"></arrow-up-icon>
    </a>
    <!-- Back to top -->
  </div>
</template>
